import React, {useState}from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IoDocumentOutline, IoPersonAddOutline } from "react-icons/io5";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useDropzone} from 'react-dropzone'
import Alert from '@mui/material/Alert';
import { IoCloudDownloadOutline } from "react-icons/io5";
import UserService from '../services/user.service';
import CircularProgress from '@mui/material/CircularProgress';


import '../sass/add-users.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
const IdentifyUsers = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openDialog, setOpenDialog] = useState(false);
    const [type, setType] = useState(1)
    const [email, setEmail] = useState('');
    const [nid, setNid] = useState('');
    // const [errors, setErrors] = React.useState([]);
    const [csvData, setCsvData] = useState([]);
    const [dataTable, setDataTable] = React.useState([]);
    const[radio, setRadio]=useState('1');
    const [noUsersFound, setNoUsersFound] = React.useState(false);
    const [load, setLoad] = React.useState(false);
    const [showStatstics, setShowStatstics] = useState(false);
    const [usersFound, setUsersFound] = useState(null);
    const [usersNotFound, setUsersNotFound] = useState(null);
    const [documentHeaderError, setDocumentHeaderError] = useState(false);

    const onDrop = (files) => {
        for (const file of files) {
            const reader = new FileReader()
            reader.onload = async (e) => {
              let enc = new TextDecoder("utf-8");
              const binaryStr = reader.result
              const data = await csvToArray(enc.decode(binaryStr));
              setCsvData(data);
            }
            reader.readAsArrayBuffer(file)
        }        
    };

    const csvToArray = async (str, delimiter = ",") => {
        const headers = str.slice(0, str.indexOf("\n")).split(delimiter).map((header) =>{return header.trim()})
        if (headers.includes('e_mail') || headers.includes('nid')) {
            setDocumentHeaderError(false);
            const rows = str.slice(str.indexOf("\n") + 1).split("\n").map((row) =>{return row.trim()})
            const isEmptyRowByCommad = rows.slice(-1)
            if(isEmptyRowByCommad === '') {
                rows.pop()
            }
            const cvsUsers = rows.map((row) => {
                const values = row.split(delimiter).map((value) =>{return value.trim()});
                const cvsUser = headers.reduce( (object, header, index) => {
                    object[header] = values[index];
                    return object;
                }, {});
                cvsUser.email=cvsUser.e_mail;
                cvsUser.localId=cvsUser.local_id;
                return cvsUser;
            });
            return cvsUsers;
        } else {
            setDocumentHeaderError(true)
            return []
        }
        
    }

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop});

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));
    const radioChange = (event) =>{
        setRadio(event.target.value)
    }


    const exportFile = () => {
        let str = convertToCSV(dataTable);
        let csv = 'data:text/csv;charset=utf-8,' + str;
        let data = encodeURI(csv);
        let link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', 'usuarios');
        link.click();
    }

      const convertToCSV = (arr) => {
        const array = [Object.keys(arr[0])].concat(arr)
        return array.map(it => {
          return Object.values(it).toString()
        }).join('\n')
      }

    const handleIdentifyUsers = async () => {
        setLoad(true)
        if(csvData.length) {
            setDataTable([]);
            const emailOrNid = radio==='1'?'email':'nid';
            await UserService.postIdentidfyUsers(csvData, emailOrNid).then( (response) => {
                const users = response.users
                users.length ? setNoUsersFound(false):setNoUsersFound(true)
                if( users.length) {
                    setDataTable(users);
                }
                setUsersFound(response.usersSuccess);
                setUsersNotFound(response.usersNotFound);
                setOpenDialog(false)
                setLoad(false)
            });
            setShowStatstics(true);
            removeFiles();
            setCsvData([]);
        }
        setLoad(false)
    }

    const removeFiles = files => {
        acceptedFiles.splice(files, 1);        // remove the file from the array
    };
  
    const handleClickClose = () => {
        setOpenDialog(false);
    };
    const handleClick =(event) =>{
        setAnchorEl(event.currentTarget)
        setCsvData([])
        setDocumentHeaderError(null)
        setDataTable([]);
        setShowStatstics(false);
        setNoUsersFound(false);
    }

    const handleClose =(ev)=>{
        if(ev ===1 || ev === 2){
            setType(ev)
            setAnchorEl(null)
            setOpenDialog(true)
            removeFiles()
            setCsvData([])
            setDocumentHeaderError(null)
        } 
    }
    const handleClose2 =(ev)=>{
        setAnchorEl(null)        
    }

    const emailHandleChange = (event) =>{
        setEmail(event.target.value);    
    }

    const nidHandleChange = (event) => {
        setNid(event.target.value);
    }

    const handleIdentifyUser = async () => {
        setUsersFound(null);
        setUsersNotFound(null);
        setShowStatstics(false);
        handleClickClose();
        let emailOrNid = radio==='1'?'email':'nid';
        if (emailOrNid === 'email') {
            await UserService.getIdentifyUser(emailOrNid, email).then( (response) => {
                response.length > 0 ? setNoUsersFound(false):setNoUsersFound(true)
                setDataTable(response);
                setOpenDialog(false)
            });
            setEmail('')
        }

        if (emailOrNid === 'nid') {
            await UserService.getIdentifyUser(emailOrNid, nid).then( (response) => {
                response.length > 0 ? setNoUsersFound(false):setNoUsersFound(true)
                setDataTable(response);
                setOpenDialog(false)
            });
            setNid('')
        }
    }


    return(
        <div>
            <Grid container className='table-users'>
                <Grid xs={9}>
                    <div className='title'>
                    <h3><strong>Identificar</strong> Usuarios</h3>
                    </div>
                </Grid>
                <Grid className='actions' container direction='row' alignItems='stretch' justifyContent="space-between"  xs={9}>
                    <Grid>
                        {dataTable.length > 0 &&
                        <Button
                        size='large'
                        id="basic-button"
                        // className='download-user'
                        onClick={exportFile}
                        >
                        Exportar <IoCloudDownloadOutline/>
                        </Button>
                    }
                    </Grid>
                    <Grid>
                        <Button
                            size='large'
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            >
                            Identificar
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose2}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={()=> {handleClose(1)}}> <IoPersonAddOutline/> Individual</MenuItem>
                            <MenuItem onClick={()=> {handleClose(2)}}> <IoDocumentOutline/>CSV</MenuItem>
                            
                        </Menu>
                    </Grid>
                </Grid>
                <Grid xs={9}>
                    <div className='user-table'>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    { showStatstics &&  <TableRow>
                                        <TableCell align='center' className="user-sucess" colSpan={2}> Users Found: <div className='amount'>{ usersFound ? usersFound : '--'}</div></TableCell>
                                        <TableCell align='center' className="user-duplicated" colSpan={2}> Users Not Found: <div className='amount'>{ usersNotFound ? usersNotFound : '--'}</div></TableCell>
                                        {/* <TableCell align='center' className="user-error" colSpan={2}> Error: {<div className='amount'>{ usersError ? usersError : '--'}</div> } </TableCell> */}
                                    </TableRow>}
                                    <TableRow>
                                        <TableCell align='center'><h3>Correo</h3></TableCell>
                                        <TableCell align='center'><h3>Módulo</h3></TableCell>
                                        <TableCell align='center'><h3>Id local</h3></TableCell>
                                        <TableCell align='center'><h3>NID</h3></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {dataTable.map((row) => (
                                    <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell key={row.e_mail} align="center">{row.e_mail}</TableCell>
                                    <TableCell key={row.name} align="center">{row.name}</TableCell>
                                    <TableCell key={row.local_id} align="center">{row.local_id}</TableCell>
                                    <TableCell key={row.nid} align="center">{row.nid}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            {/* {errors.length > 0  && ( errors.map((error) => ( 
                                <Alert  margin='10px' variant='outlined' severity="error">{error.msg} </Alert> 
                                ))
                            )} */}
                            { noUsersFound && ( <Alert  margin='10px' variant='outlined' severity="info">{"No se encontraron usuarios"}</Alert> )}
                        </TableContainer>
                    </div>
                    
                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='dialog-user'
            >
                {
                    type=== 1?
                    <div className='one-user'>
                    <DialogTitle>{"Identificar un solo usuario"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Llena el siguientes campos
                    </DialogContentText>
                    <div className='radio'>
                        <RadioGroup row aria-label="gender" defaultValue="1" name="row-radio-buttons-group" onChange={radioChange}>
                            <FormControlLabel value="1" control={<Radio />} label="Correo" />
                            <FormControlLabel value="2" control={<Radio />} label="NID" />
                        </RadioGroup>
                        </div>
                    <div className='add-form'>
                        {radio === '1' ?
                            <TextField id="outlined-basic" label="Escribe el correo" variant="outlined" value={email} onChange={emailHandleChange}/>
                            : <TextField id="outlined-basic" label="Escribe el NID" variant="outlined" value={nid} onChange={nidHandleChange}/>
                        }
                    </div>
                   
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClickClose} className='cancel-button'>Cancelar</Button>
                    <Button onClick={handleIdentifyUser} className='primary-button'>Identificar Usuario</Button>
                    </DialogActions>
                    </div>
                    :
                    <div className='many-user'>
                    <DialogTitle>{"Identificar usuarios por bulto"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Escoge la opción por la cuál se van a agregar los usuarios
                    </DialogContentText>
                    <div className='add-form'>
                        <div className='radio'>
                            <RadioGroup row aria-label="gender" defaultValue="1" name="row-radio-buttons-group" onChange={radioChange} >
                                <FormControlLabel value="1" control={<Radio />} label="Correo" />
                                <FormControlLabel value="2" control={<Radio />} label="NID" />
                            </RadioGroup>
                        </div>
                        <p>Carga el archivo</p>
                        <div className='file'>
                            <div {...getRootProps()}>
                                <input name='cvsFile' {...getInputProps()} />
                                <p>Arrastra y suelta el archivo aquí, o <a href="#file">escoge uno desde tu computadora</a></p>
                            </div>
                        </div>
                        {files}
                        {documentHeaderError && ( <Alert className='header-error' margin='10px' variant='outlined'  severity="error">Encabezados de columnas incorrectas</Alert> )}     
                    </div>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClickClose} className='cancel-button'>Cancelar</Button>
                    <Button disabled={load} onClick={handleIdentifyUsers} className='primary-button'>Identificar</Button>
                    {load && (
                        <CircularProgress
                            size={40}
                            sx={{
                            // color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                            }}
                        />
                    )}
                    </DialogActions>
                </div>

                }
                
            </Dialog>                            

        </div>
    )

}

export default IdentifyUsers;