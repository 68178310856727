import React, {useState}from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import { IoDocumentOutline, IoPersonAddOutline } from "react-icons/io5";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';
import {useDropzone} from 'react-dropzone'
import { IoCloudDownloadOutline } from "react-icons/io5";
import UserService from '../services/user.service';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

// import ModuleService from '../services/module.service';

import '../sass/add-users.scss';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddUsers = () => {  
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [type, setType] = useState(1)
    const [email, setEmail] = useState('');
    const [modulo, setModulo] = useState('');
    const [localId, setLocalId] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [noUsersAdded, setNoUsersAdded] = React.useState(false);
    const [errors, setErrors] = useState([]);
    const [load, setLoad] = useState(false);
    const [usersSuccess, setUsersSuccess] = useState(null);
    const [usersDuplicated, setUsersDuplicated] = useState(null);
    const [usersError, setUsersError] = useState(null);
    const [showStatstics, setShowStatstics] = useState(false);
    const [documentHeaderError, setDocumentHeaderError] = useState(false);

    // const [modules, setModules] = useState([]);
    const onDrop = (files) => {
        for (const file of files) {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = async (e) => {
              let enc = new TextDecoder("utf-8");
              const binaryStr = reader.result
              const data = await csvToArray(enc.decode(binaryStr));
              setCsvData(data);
            }
            reader.readAsArrayBuffer(file)
        }
    };

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop});

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
    ));

    // const getModules = () => {
    //     ModuleService.getModules().then( response => {
    //         setDataTable(response);
    //     }, error => {
    //         if (error.response) {
    //             setErrors(error.response.data.errors);
    //         }
    //     });
    // }
    const csvToArray = async (str, delimiter = ",") => {
        const headers = str.slice(0, str.indexOf("\n")).split(delimiter).map((header) =>{return header.trim()})
        if (headers.includes('e_mail') && headers.includes('module') && headers.includes('local_id') ) {
            setDocumentHeaderError(false)
            const rows = str.slice(str.indexOf("\n") + 1).split("\n").map((row) =>{return row.trim()})
            const isEmptyRowByCommad = rows.slice(-1)
            if(isEmptyRowByCommad === '') {
                rows.pop()
            }
            const cvsUsers = rows.map((row) => {
                const values = row.split(delimiter).map((value) =>{return value.trim()});
                const cvsUser = headers.reduce( (object, header, index) => {
                    object[header] = values[index];
                    return object;
                }, {});
                cvsUser.email=cvsUser.e_mail;
                cvsUser.localId=cvsUser.local_id;
                return cvsUser;
            });
            return cvsUsers;
        } else {
            setDocumentHeaderError(true)
            return []
        }
    }

    const removeFiles = files => {
        acceptedFiles.splice(files, 1);        // remove the file from the array
    };

    const exportFile = () => {
        let str = convertToCSV(dataTable);
        let csv = 'data:text/csv;charset=utf-8,' + str;
        let data = encodeURI(csv);
        let link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', 'usuarios');
        link.click();
    }

    const convertToCSV = (arr) => {
        const array = [Object.keys(arr[0])].concat(arr)
        return array.map(it => {
            return Object.values(it).toString()
        }).join('\n')
    }

    const handleSubmitMultipleUsers = async () => {
        setLoad(true);
        console.log(documentHeaderError)
        if (csvData.length) {
            setDataTable([]);
            await UserService.postAddUsers(csvData).then( response => {
                const users = response.users
                setUsersSuccess(response.usersSuccess)
                setUsersDuplicated(response.usersDuplicated)
                setUsersError(response.usersError)
                users.length > 0 ? setNoUsersAdded(false):setNoUsersAdded(true)
                if (users) {
                    setDataTable(users);
                }
            }, error => {
                if (error.response) {
                    setErrors(error.response.data.errors);
                }
                setLoad(true);
                setDataTable([]);
            }
            );
            setShowStatstics(true);
            setOpenDialog(false);
            removeFiles();
            setCsvData([])
        }
        setLoad(false)
    }
  
    const handleDialogCancel = () => {
      setOpenDialog(false);
      removeFiles()
      setCsvData([])
      setDocumentHeaderError(null)
    };
    const handleClick =(event) =>{
        setAnchorEl(event.currentTarget)
    }

    const handleClose =(ev)=>{
        if(ev ===1 || ev === 2){
            setType(ev)
            setAnchorEl(null)
            setOpenDialog(true)
        } 
    }
    const handleClose2 =(ev)=>{
        setAnchorEl(null)
    }

    const emailHandleChange = (event) =>{
        setEmail(event.target.value);    
    }

    const moduleHandleChange = (event) =>{
        setModulo(event.target.value);
    }

    const localIdHandleChange = (event) => {
        setLocalId(event.target.value);
    }

    const handleSubmitIndividual = async () => {
        setShowStatstics(false);
        setUsersSuccess(null)
        setUsersDuplicated(null)
        setUsersError(null)

        const user = {
            email: email,
            module: modulo,
            localId: localId
        }

        // if (email !== "" && modulo !== "" && localId !== "" ) {
        const users = [];
        users.push(user)
        setDataTable([]);
        await UserService.postAddUsers(users).then( (response) => {
            const usersResponse = response.users;
            usersResponse.length > 0 ? setNoUsersAdded(false):setNoUsersAdded(true)
            if (usersResponse.length) {
                setDataTable(usersResponse);
            }
            setEmail('');
            setModulo('');
            setLocalId('');
            setOpenDialog(false)
        }, error => {
            if (error.response) {
                setErrors(error.response.data.errors);
            }
            setDataTable([]);
        });
        handleDialogCancel();    
    }
    return(
        <div>
            
            <Grid container className='table-users' >
                <Grid item={true} xs={9}>
                    <div className='title'>
                        <h3><strong>Agregar</strong> Usuarios</h3>
                    </div>
                </Grid>                   
                <Grid className='actions' container direction='row' alignItems='stretch' justifyContent="space-between"  xs={9}>
                    <Grid>
                        {dataTable.length > 0 &&
                        <Button
                        size='large'
                        id="basic-button"
                        // className='download-user'
                        onClick={exportFile}
                        >
                        Exportar <IoCloudDownloadOutline/>
                        </Button>
                    }
                    </Grid>
                    <Grid>
                        <Button
                            size='large'
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            >
                            Agregar
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose2}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={()=> {handleClose(1)}}> <IoPersonAddOutline/> Individual</MenuItem>
                            <MenuItem onClick={()=> {handleClose(2)}}> <IoDocumentOutline/>CSV</MenuItem>
                            
                        </Menu>
                    </Grid>                    
                </Grid>
                <Grid item={true} xs={9}>
                    <div className='user-table'>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                { showStatstics &&  <TableRow>
                                    <TableCell align='center' className="user-sucess" colSpan={1}> Success: <div className='amount'>{ usersSuccess ? usersSuccess : '--'}</div></TableCell>
                                    <TableCell align='center' className="user-duplicated" colSpan={2}> Duplicated: <div className='amount'>{ usersDuplicated ? usersDuplicated : '--'}</div></TableCell>
                                    <TableCell align='center' className="user-error" colSpan={2}> Error: {<div className='amount'>{ usersError ? usersError : '--'}</div> } </TableCell>
                                </TableRow>}
                                <TableRow>
                                    <TableCell align='center'><h4>Correo</h4></TableCell>
                                    <TableCell align='center'><h4>Módulo</h4></TableCell>
                                    <TableCell align='center'><h4>Id local</h4></TableCell>
                                    <TableCell align='center'><h4>NID</h4></TableCell>
                                    <TableCell align='center'><h4>Status</h4></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {dataTable.map((user) => (
                                    <TableRow key={user.email}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell align="center">{user.email}</TableCell>
                                    <TableCell align="center">{user.module}</TableCell>
                                    <TableCell align="center">{user.localId}</TableCell>
                                    <TableCell align="center">{user.nid}</TableCell>
                                    <TableCell align="center" >
                                        { user.status === 'Error' && <div className='status-label' style={{padding:10, background:'#ef5350'}}>{user.status} 
                                            <Tooltip title={user.error}>
                                                <IconButton>
                                                    <ErrorOutlineOutlinedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>}                              
                                        { user.status === 'Duplicated' && <div className='status-label' style={{padding:10, background:'#ffe082'}}>{user.status}</div>}                                       
                                        { user.status === 'Success' && <div className='status-label' style={{padding:10, background:'#66bb6a'}}>{user.status}</div>}                               
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>

                            </Table>
                            {errors.length > 0  && ( errors.map((error) => ( 
                                <Alert  margin='10px' variant='outlined' severity="error">{error.msg} </Alert> 
                                ))
                            )}
                            { noUsersAdded && ( <Alert  margin='10px' variant='outlined' severity="info">No se agregaron usuarios</Alert> )}
                        </TableContainer>
                    </div>                    
                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='dialog-user'
            >
                {
                    type=== 1?
                    <div className='one-user'>
                    <DialogTitle>{"Agregar un solo usuario"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Llena los siguientes campos
                    </DialogContentText>

                    <div className='add-form'>
                        <TextField id="outlined-basic" required label="Escribe el correo" variant="outlined" value={email} onChange={emailHandleChange}/>
                        <TextField id="outlined-basic" required label="Escribe el módulo al que pertenece" variant="outlined" onChange={moduleHandleChange} value={modulo}/>
                        <TextField id="outlined-basic" required label="Escribe el id local" variant="outlined" value={localId} onChange={localIdHandleChange}/>
                    </div>
                   
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleDialogCancel} className='cancel-button'>Cancelar</Button>
                    <Button onClick={handleSubmitIndividual} className='primary-button'>Agregar</Button>
                    </DialogActions>
                    </div>
                    :
                    <div className='many-user'>
                    <DialogTitle>{"Agregar usuarios por bulto"}</DialogTitle>
                    <DialogContent>
                        <div className='add-form'>
                            <p>Carga el archivo</p>
                            <div className='file'>
                                <div {...getRootProps({className:'dropzone'})}>
                                    <input name='cvsFile' {...getInputProps()} />
                                    <p>Arrastra y suelta el archivo aquí, o <a href='#file'>escoge uno desde tu computadora</a></p>
                                </div>                                
                            </div>
                            {files}
                            {documentHeaderError && ( <Alert className='header-error' margin='10px' variant='outlined'  severity="error">Encabezados de columnas incorrectas</Alert> )}     
                        </div>

                    </DialogContent>
                    

                    <DialogActions>
                    <Button onClick={handleDialogCancel} disabled={load} className='cancel-button'>Cancelar</Button>                    
                    <Button disabled={load} onClick={handleSubmitMultipleUsers} className='primary-button'>Agregar</Button>
                    {load && (
                        <CircularProgress
                            size={40}
                            sx={{
                            // color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                            }}
                        />
                    )}
                    </DialogActions>
                </div>
                }
            </Dialog>
        </div>
    )

}

export default AddUsers;