// import axios from 'axios';
import authHeader from './auth-header';
import api from './api'
class UserService {

  getIdentifyUser(emailOrNid, email) {
    try {
      return api
      .get(`user/${emailOrNid}/${email}`,
      { headers: authHeader()}
      ).then(res => {
        return res.data;
      })
    } catch (error) {
      return error
    }
  }

  postIdentidfyUsers(users, byEmailOrNid) {
    try {
      return api
      .post(`users/identify/${byEmailOrNid}`,{
        users: users,
      },{ headers: authHeader()}
      ).then(res => {
        return res.data;
      })
    } catch (error) {
      return error
    }
  }

  async postAddUsers(users) {
    try {
      return await api
      .post(`users`,{
        users: users,
      },{ headers: authHeader()}
      ).then(res => {
        return res.data;
      })
    } catch (error) {
      return error
    }
  }
}

export default new UserService();
