// import axios from "axios";
// import Cookies from 'universal-cookie';
import TokenService from './token.service'
import api from './api'
class AuthService {
  
  login(email, password) {
    try {
      return api
      .post("auth",
      {
        email,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
          sessionStorage.setItem('email', email);
        }
        return response.data;
      });
    } catch (error) {
      return error
    }
    
  }

  logout() {
    // localStorage.removeItem("user");
    TokenService.removeUser();
  }

  getCurrentUser() {
    // return JSON.parse(localStorage.getItem('user'));
    return TokenService.getUser();
  }
}

export default new AuthService();
