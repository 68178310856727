import authHeader from './auth-header';
import api from './api';
class ModuleService {
  getModules() {
      try {
          return api
          .get(`modules`,
          { headers: authHeader()}
          ).then(res => {
          return res.data;
          })
      } catch (error) {
          return error
      }
  }
  postAddModule(moduleName) {    
    try {
      return api
      .post(`modules`,{
        name: moduleName,
      },{ headers: authHeader()}
      ).then(res => {
        return res.data;
      })
    } catch (error) {
      return error
    }
  }
}

export default new ModuleService();
