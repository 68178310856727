import { Backdrop, Button, Card, CircularProgress, TextField, Alert } from "@mui/material";
import React, {useState} from "react";
import {Link, useHistory} from 'react-router-dom'
import '../sass/login-form.scss';
// import Cookies from 'universal-cookie';
// import axios from 'axios';

// const authURL = `${process.env.REACT_APP_MVP_URL}/auth`;

import AuthService from "../services/auth.service";

const LoginForm = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const [errors, setErrors] = React.useState([])
    // const cookies = new Cookies();
    const handleClose = () => {
        setOpen(false);
    }

    const emailHandleChange = (event) => {
        setEmail(event.target.value);
    }

    const passwordHandleChange = (event) => {
        setPassword(event.target.value);
    }

    const keyUpPress = (e) => {
        if (e.charCode === 13) {
            handleLoginSubmit();
        }

        if (e.keyCode === 13) {
            handleLoginSubmit();
        }
    }
    const handleLoginSubmit = async () => {
        setOpen(true);
        AuthService.login(email, password).then(
            () => {
                history.push('/home')
            },
            error => {
                setOpen(false);
                console.log(error.response)
                if (error.response) {
                    setErrors(error.response.data.errors);
                }    
            }
        )
    }

    return(
        <div className="login-form">
            <Backdrop
                sx={{color:'#fff', zIndex:(theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Card className="form-card">
                <div className="form-logo">
                    <h1>CIMA</h1>
                </div>
                <div className="form">
                    <div className="title">
                        <h4>Login</h4>
                    </div>
                    <TextField id="outlined-basic" label="Usuario" variant="outlined" onKeyPress={keyUpPress} value={email} onChange={emailHandleChange}/>
                    <TextField id="outlined-basic" label="Contraseña" variant="outlined" onKeyPress={keyUpPress} value={password} type="password" onChange={passwordHandleChange}/>
                    <div className="form-forgot-password">
                        <Link to='/'>Olvidé mi contraseña</Link>
                    </div>
                </div>
                {errors.length > 0  && ( errors.map((error) => ( <Alert severity="error">{error.msg}</Alert>)) ) }
                <div className="action">
                    <Button variant="contained" onClick={handleLoginSubmit}>Login</Button>
                </div>

            </Card>
        </div>
    )
};

export default LoginForm;