import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {IoIosAdd, IoIosSearch, IoIosArrowDown, IoIosLogOut} from "react-icons/io";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import AuthService from '../services/auth.service'


import '../sass/navbar.scss'
const Navbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const location = useLocation();
    const history = useHistory();

    const handleClick =(event) =>{
        setAnchorEl(event.currentTarget)
    }

    const handleClose =()=>{
        setAnchorEl(null)
    }

    const handleLogout = () => {
        AuthService.logout();
        setAnchorEl(null);
        history.push('/')
    }
    return(
        <div className='navbar'>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                    <Typography variant="h6" component="div" align='left' sx={{ flexGrow: 1 }}>
                        CIMA
                    </Typography>
                    <Button variant="text"  style={{background: location.pathname==='/home'?'rgb(242, 240, 240)': 'white'}} startIcon={<IoIosAdd/>} onClick={()=>history.push("home")}>
                        Agregar
                    </Button>
                    <Button variant="text" style={{background: location.pathname==='/identify'?'rgb(242, 240, 240)': 'white'}} startIcon={<IoIosSearch/>} onClick={()=>history.push("/identify")}>
                        Identificar
                    </Button>
                    <Button variant="text" style={{background: location.pathname==='/module'?'rgb(242, 240, 240)': 'white'}} onClick={()=>history.push("/module")} >Modulo</Button>
                    <div>
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        className='menu-user-nav'>
                        {sessionStorage.getItem('email') || 'email'} <IoIosArrowDown/>
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleLogout}><IoIosLogOut/>Salir</MenuItem>
                        
                    </Menu>
                    </div>
                    </Toolbar>
                </AppBar>
            </Box>

        </div>
    )
}

export default Navbar;