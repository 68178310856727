import React from 'react';
import Navbar from '../components/Navbar';
import IdentifyUsers from '../components/IdentifyUsers';
const HomeIdentify = () => {
    return(
        <div>
            <Navbar></Navbar>
            <IdentifyUsers></IdentifyUsers>
        </div>
    )
}

export default HomeIdentify;