import React, {useState, useEffect}from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {IoIosAdd} from "react-icons/io";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {TextField} from '@mui/material';
import Slide from '@mui/material/Slide';
import ModuleService from '../services/module.service';
import '../sass/module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Modules = () => {  
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [modulo, setModulo] = useState('');
    const [dataTable, setDataTable] = useState([]);
    // const [message, setMessage] = useState('');
    // const [isError, setError] = useState(null)
    
    const handleGetModules = async () => {
        const modules = await ModuleService.getModules();
        setDataTable(modules)
    }
    const handleDialogCancel = () => {
      setOpenDialog(false);
    //   removeFiles()
    };
    const handleClick =(event) =>{
        setAnchorEl(event.currentTarget)
    }

    const handleClose =(ev)=>{
        if(ev ===1 || ev === 2){
            // setType(ev)
            setAnchorEl(null)
            setOpenDialog(true)
        } 
    }
    const handleClose2 =(ev)=>{
        setAnchorEl(null)
        
    }

    const moduleHandleChange = (event) =>{
        setModulo(event.target.value);
    }
    const handleSubmitModule = async () => {
        await ModuleService.postAddModule(modulo).then(() => {
            setModulo('');
            handleGetModules();
        });
        setOpenDialog(false);
    }
    useEffect(() => {
        handleGetModules()
    },[])
    return(
        <div>
            
            <Grid container className='table-modules'>
                <Grid item xs={4}>
                    <div className='title'>
                        <h3><strong>Módulos</strong></h3>
                    </div>
                    
                    <div className='actions'>
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        className='menu-user'>
                        Agregar
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose2}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={()=> {handleClose(1)}}> <IoIosAdd/> Agregar Módulos</MenuItem>
                        
                    </Menu>
                    </div>
                    <div className='user-table'>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 400 }} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell align='right'><h3>Id</h3></TableCell>
                                    <TableCell align='left'><h3>Nombre</h3></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {dataTable.map((module) => (
                                    <TableRow
                                    key={module.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell align="right">{module.id}</TableCell>
                                    <TableCell align="left">{module.name}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>                    
                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='dialog-user'
            >
                {
                    <div className='one-user'>
                    <DialogTitle>{"Agregar Módulo"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Llena el siguiente campo
                    </DialogContentText>
                    <div className='add-form'>
                        <TextField id="outlined-basic" label="Escribe el nombre del nuevo módulo" variant="outlined" value={modulo} onChange={moduleHandleChange}/>
                    </div>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleDialogCancel} className='cancel-button'>Cancelar</Button>
                    <Button onClick={handleSubmitModule} className='primary-button'>Agregar Módulo</Button>
                    </DialogActions>
                    </div>
                }
            </Dialog>                            

        </div>
    )

}

export default Modules;