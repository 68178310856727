import React from "react";
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    return (
        <Route
        {...restOfProps}
        render = { (props) => isAuthenticated ? <Component {...props}></Component> : <Redirect to="/" />
        }
        />
    )
}

export default ProtectedRoute;