import React from "react";
import LoginForm from '../components/LoginForm';
import '../sass/login.scss';

const Login = () => {
    return(
        <div className="login">
            <LoginForm></LoginForm>
        </div>
    )
}

export default Login;