import React from "react";
import Navbar from '../components/Navbar';
import AddUsersComponent from '../components/AddUsers';


const Home = () => {
    return(
        <div>
            <Navbar></Navbar>
            <AddUsersComponent></AddUsersComponent>
        </div>
    )
}

export default Home;