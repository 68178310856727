import React from "react";
import Navbar from '../components/Navbar';
import Modules from '../components/Modules';


const Module = () => {
    return(
        <div>
            <Navbar></Navbar>
            <Modules></Modules>
        </div>
    )
}

export default Module;