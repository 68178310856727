import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute'
import Login from '../pages/Login';
import Home from '../pages/Home';
import Identify from '../pages/HomeIdentify';
import Module from '../pages/Modules';

const Routing = () => {
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Login}/>
                <ProtectedRoute path="/home" component={Home} />
                <ProtectedRoute path="/identify" component={Identify} />
                <ProtectedRoute path="/module" component={Module} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routing;