import Cookies from 'universal-cookie';
const cookies = new Cookies();
class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.refreshToken;
    }
  
    getLocalAccessToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.accessToken;
    }
  
    updateLocalAccessToken(token) {
      let user = JSON.parse(localStorage.getItem("user"));
      user.accessToken = token;
      localStorage.setItem("user", JSON.stringify(user));
    }
  
    getUser() {
      return JSON.parse(localStorage.getItem("user"));
    }
  
    setUser(user) {
      localStorage.setItem("user", JSON.stringify(user));
      cookies.set('accessToken', user.accessToken, {path:'/'})
      localStorage.setItem('isAuthenticated', true);
    }
  
    removeUser() {
      localStorage.removeItem("user");
      cookies.remove('accessToken', {path:'/'})
      localStorage.removeItem('isAuthenticated');
      sessionStorage.removeItem('email');      
    }
  }
  
  export default new TokenService();